.image-upload-container {
    text-align: center;
  }
  
  .upload-button {
    display: block;
    border:  1px dashed #3498db;
    color: #3498db;
    padding: 10px 20px;
     width:160px;
    cursor: pointer;
    margin: 6px;
    border-radius: 5px;
  }
  
  .image-preview {
    text-align: center;
  }
  
  .image-preview img {
    max-width: 100%;
    max-height: 200px;
  }
  