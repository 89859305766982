
.chart-container {
  flex: 1;
}

.tv-lightweight-charts {
  flex: 1;
  height: 100%;
  width:100%;
}


  .containertype11 {
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    @media(min-width: 600px) {
      flex-direction: row;
    }
  }
  
  .containertype11 > div {
    height: 45px;
   
   
  }

  .assets{
  
    display: flex;
    flex-direction: row;
    padding:9px;
  }

  .assetbox{
    padding-left: 10px;
   
  }

  .vl {
    border-right: 1.3px solid #aaa;
    height: 17px;
    margin:7px;
   
  }

  .main_price{
    margin-left: 2px;
    margin-top: 7px;
    font-size: 21px;
    padding:5px;
  }


  .banner-up{
    font-size: 12px;
    text-align: left;
  }

  .crypto-data{
    margin-left:12px;
    margin-top: 6px;
    padding:7px;
  }
  .dark{
    color: var(--onsurface);
  }

