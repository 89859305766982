@media (max-width: 767px) {
    .trading__image {
        display:none;
    }
}




.title11{
    max-width: 400px;
    margin: auto;
    text-align: center;
    font-family: "Poppins", sans-serif;
    h3{
      font-weight: bold;
    }
    p{
      font-size: 12px;
      color: #118a44;
      &.msg{
        color: initial;
        text-align: initial;
        font-weight: bold;
      }
    }
  }
    
  .otp-input-fields{
    margin: auto;
    max-width: 400px;
    width: auto;
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    
    .otp_box{
      height: 40px;
      width: 40px;
      background-color: transparent;
      border-radius: 4px;
      border: 1px solid #3673ff;
      text-align: center;
      outline: none;
      font-size: 16px;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
  
      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }
      &:focus{
        border-width: 2px;
        border-color: darken(#3673ff, 5%);
        font-size: 20px;
      }
    }
  }
  
