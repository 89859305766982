:root {
  --main:#f5f9fc;
  --tablemain:#222;
  --tabletd:#ccc;
}

.is_dark {
  --main:black;
  --tablemain:#ccc;
  --tabletd:#474d57;
}


.orders-ul{
    overflow-y: auto;
    height: 337px;
    margin-top:7px;


    .container122 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans", sans-serif;
}

.crypto-card {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid var(--tabletd);
 

}

.left-section {
  
  text-align: left;
  margin-left: 10px;
  width:80px;
}

.center-section{
    
    p{
        font-size: 10px;
      
    }
}

.right-section {
  flex: 1;
  text-align: right;
  margin-right: 10px;
  

  button{
    background-color:#d33535;
    color:white;
    padding:3px;
    font-size: 9px;
    border: none;
    border-radius: 2px;
  
    float:right;

}
  

 
}

.crypto-card img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
 
}

.crypto-name {
  font-size: 13px;
  font-weight: 500;
 
 
  span{
  
    color:gray;
    font-size: 11px;
    
  }
}

.crypto-price {
  font-size: 8px;
 

}

.crypto-volume {
  font-size: 10px;
  color: #666;
  margin-bottom: 4px;
}


}


