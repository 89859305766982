.containerTimer {
    position: relative;
   font-size: 30px;
    color: #3773ff;
    font-weight: bold;
    display: flex;
   
  }
  .containerTimer div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .containerTimer div:not(:last-child) {
    margin-right: 20px;
  }
  .containerTimer span {
    border: 2px solid;
    padding: 10px 20px;
    border-radius: 6px;
  }
  .containerTimer span:not(:last-child) {
    margin-right: 13px;
  }


  .timer-text{
      color:#e36a27;
     font-size: 40px;

  }
  