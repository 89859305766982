/* ImageLoader.css */

.image-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Set a high z-index to ensure it covers other components */
  }
  
  .image-loader img {
    width: 100px; /* Set the size of your loading image */
  }

.image-text{
    position: absolute;
    color: white;
   margin-top: 70px;
}
 
  