:root {
  --main:#f5f9fc;
  --tablemain:#222;
  --tabletd:#ccc;
  --navcolor:#fff;
}

.is_dark {
  --main:black;
  --tablemain:#ccc;
  --tabletd:#474d57;
  --navcolor:#141416;
}

.container1{
  max-width: 960px; /* set the maximum width of the container */
  margin: 10px auto; /* center the container horizontally */
  padding: 0 16px; /* add some padding to the left and right of the container */

}

@media screen and (max-width: 540px) {
  .mainpage{
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
  
  }
}

/* For Tablets */
@media screen and (min-width: 540px) and (max-width: 780px) {
  .mainpage{
    margin-left: 65px;
    margin-right: 65px;
    margin-top: 10px;
    margin-bottom: 10px;
  
  }
}

/* For Tablets */
@media screen and (min-width: 780px) {
  .mainpage{
    margin-left: 65px;
    margin-right: 65px;
    margin-top: 10px;
    margin-bottom: 10px;
  
  }
}





.header_pair{
  color:#d33535;
}

.section1{
  padding:15px;
  border: 0.8px solid #ddd; 
  border-radius: 3px;
 
  
}

.trade_pair {
  width:100%;
  border:0.8px solid #aaa;
  border-radius: 2px;
  margin-bottom: 20px;
  margin-top: 10px;
}



.trade_pair1{
  width:100%;
  border:0.8px solid #aaa;
  border-radius: 2px;
}




.header_pairType{
  display:flex; 
  background-color: "#333";
  
}


.up{
  color: #5cb85b;
  font-family:"arial";
}

.down{
  color:#d33535;
  font-family:"arial";
}









.table-scroll{
 
  display: block;
  empty-cells: show;
 /* Decoration */
  border-spacing: 0;

}

.table-scroll thead{
  
  position:relative;
  display: block;
  width:100%;
  overflow-y: scroll;
}

.table-scroll tbody{
  /* Position */
  display: block; position:relative;
  width:100%; overflow-y:scroll;
  /* Decoration */
  border-top: 1px solid rgba(0,0,0,0.2);

  max-height:395px; 
  overflow: scroll;
}




.table-scroll tr{
  width: 100%;
  display:flex;
}

.table-scroll td,.table-scroll th{
  flex-basis:100%;
  flex-grow:2;
  display: block;
  padding: 0.4rem;
  text-align:left;
 
}


.table-scroll td,.table-scroll1 td,.table-scroll3 td{
  border-top:0.5px solid var(--tabletd);
  
}



/* Other options */

.table-scroll.small-first-col td:first-child,
.table-scroll.small-first-col th:first-child{
  flex-basis:20%;
  flex-grow:1;
}









.table-scroll1{
 
  display: block;
  empty-cells: show;
 /* Decoration */
  border-spacing: 0;

}

.table-scroll1 thead{
  
  position:relative;
  display: block;
  width:100%;
  overflow-y: scroll;
}

.table-scroll1 tbody{
  /* Position */
  display: block; position:relative;
  width:100%; overflow-y:scroll;
  /* Decoration */
  border-top: 1px solid rgba(0,0,0,0.2);

  max-height:186px; 
  overflow: scroll;
}




.table-scroll1 tr{
  width: 100%;
  display:flex;

}

.table-scroll1 td,.table-scroll1 th{
  flex-basis:100%;
  flex-grow:2;
  display: block;
  padding: 0.4rem;
  text-align:left;
}







.table-scroll3{
 height:330px;
 overflow:hidden;
  display: block;
  empty-cells: show;
 /* Decoration */
  border-spacing: 0;

}

.table-scroll3 thead{
  
  position:relative;
  display: block;
  width:100%;
  overflow-y: scroll;
}

.table-scroll3 tbody{
  /* Position */
  display: block; position:relative;
  width:100%; overflow-y:scroll;
  /* Decoration */
  border-top: 1px solid rgba(0,0,0,0.2);

  max-height:300px; 
  overflow: scroll;
}




.table-scroll3 tr{

  width: 100%;
  display:flex;
}

.table-scroll3 td,.table-scroll3 th{
  flex-basis:100%;
  flex-grow:2;
  display: block;
  padding: 0.4rem;
  text-align:left;
}



/* Other options */

.table-scroll3.small-first-col td:first-child,
.table-scroll3.small-first-col th:first-child{
  flex-basis:20%;
  flex-grow:1;
}






.table-scroll4{
 
  display: block;
  empty-cells: show;
 /* Decoration */
  border-spacing: 0;

}

.table-scroll4 thead{
  position:relative;
  display: block;
  width:100%;
  overflow-y: scroll;
}



.table-scroll4 tbody{
  /* Position */
  display: block; position:relative;
  width:100%; overflow-y:scroll;
  /* Decoration */
  border-top: 1px solid rgba(0,0,0,0.2);
 
  max-height:300px; 
  overflow: scroll;
}




.table-scroll4 tr{
  width: 100%;
  display:flex;
}

.table-scroll4 td,.table-scroll4 th{
  flex-basis:100%;
  flex-grow:2;
  display: block;
  padding: 0.4rem;
  text-align:left;
}



/* Other options */

.table-scroll4.small-first-col td:first-child,
.table-scroll4.small-first-col th:first-child{
  flex-basis:20%;
  flex-grow:1;
}





td{
    border-top: 0.4px solid #ddd;
    font-size: 10.5px;
    font-weight: 500;
   
}


tr:hover
{
    background-color:#f5f9fc;
    
}


td{
  color:#555;
}



.body-half-screen{
  min-height: 100%;
}

.small-col{flex-basis:10%;}

.tab-design{
  background-color:#3574ff;
  color:white;
}

.tab-design1{
  background-color:#d33535;
  color:white;
}

.tab-design2{
  background-color:#5cb85b;
  color:white;
}

#tab-basic{
  background-color: var(--main);
  height:35px;
}


.star-text{
font-size: 14px;
text-align: center;
}





.searchbox {
  width: 100%;
  border: 1px solid #aaa;
  border-radius: 3px;
  outline: none;
  box-sizing: border-box;
  transition: 0.3s;
  background-color: transparent;
}



.searchbox:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 1px 0 dodgerBlue;
}

.inputWithIcon .searchbox {
  padding-left: 40px;
}

.inputWithIcon {
  position: relative;
}

.inputWithIcon i {
  position: absolute;
  right: 0;
  top: 8px;
  padding: 9px 8px;
  color: #aaa;
  transition: 0.3s;
}

.inputWithIcon .searchbox:focus + i {
  color: dodgerBlue;
}

.inputWithIcon.inputIconBg i {
 
  color: #777;
  margin: 1px 10px;

  border-radius: 4px 0 0 4px;
}

.inputWithIcon.inputIconBg .searchbox:focus + i {
 
  color: dodgerBlue;
}






.field {
  display:flex;
  position:realtive;
  margin:5px auto;
  width:100%;

}

.field>input[type=text],
.field>button {
  display:block;
  font:20px;
}

.field>input[type=text] {
  flex:1;
   font-size:10px;
   color:#333;
   padding-left: 10px;
  border:1px solid #bbb;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.field>button {
  padding:5px 8px;
  background-color: #3773ff;
  color: #fff;
  border:none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}




.field1 {
  display:flex;
  position:realtive;
  margin:25px auto;
  width:80%;
}

.field1>input[type=text],
.field1>button {
  display:block;
  font:20px;
}

.field1>input[type=text] {
  flex:1;
   font-size:14px;
   color:#333;
   padding-left: 10px;
  border:1px solid #bbb;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.field1>button {
  padding:5px 8px;
  background-color: #3773ff;
  color: #fff;
  border:none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  font-weight:bold;
}


@media only screen and (max-width: 600px) {
  .field1>input[type=text] {
    flex:1;
     font-size:11px;
     color:#333;
     padding-left: 10px;
    border:1px solid #bbb;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }


  .field1>button {
    padding:5px 8px;
    background-color: #3773ff;
    color: #fff;
    border:none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 10px;
    font-weight:bold;
  }
}







.field2 {
  display:flex;
  position:realtive;
  
  width:100%;
  max-width: 350px;
  float: right;
  padding-bottom: 20px;
}

.field2>input[type=text],
.field1>button {
  display:block;
  font:20px;
}

.field2>input[type=text] {
  flex:1;
   font-size:12px;
   color:#333;
   padding-left: 10px;
  border:1px solid #bbb;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.field2>button {
  padding:5px 8px;
  background-color: #3773ff;
  color: #fff;
  border:none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 12px;
  font-weight:bold;
}


@media only screen and (max-width: 600px) {
  .field2>input[type=text] {
    flex:1;
     font-size:11px;
     color:#333;
     padding-left: 10px;
    border:1px solid #bbb;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }


  .field2>button {
    padding:5px 8px;
    background-color: #3773ff;
    color: #fff;
    border:none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 10px;
    font-weight:bold;
  }
}




.officalAdvise{
  padding: 4% 12% 4% 12%;
  text-align: left;
}


.pagination-button{
  display: flex;
  float: right;
  padding-bottom: 30px;
  padding-top: 10px;

  li{
    padding: 5px;
    color:#0b6efd;


   

    button{
      color: white;
    }
   
  }
}


.tab-tab2{
  display: flex;
  border-bottom: 1px solid #a3a3a3;

  .active{
    border-bottom: 2.5px solid #0b6efd;
    border-radius: 1px;
    margin-bottom: 1px;
    color:#0b6efd;
  }

 li{
   padding-left: 14px;
   padding-right: 14px;
   padding-bottom: 3px;
   font-weight: 600;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
 }
}



.market-table{

  
  th{
    padding:10px;
    border-bottom: none;
    padding-left: 25px;
  }

    tr{
    padding-top: 10px;
  
    }
    td{
      vertical-align: middle;
      font-size: 15px;
      font-weight: 600;
      justify-content: left;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      padding:17px;
    }

 
}



.no-datatext{
  color:gray;
  font-size: 15px;
}



.field2{
  input{
    background-color: transparent;
    border:1px solid black;
  }
}