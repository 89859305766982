@import url(https://fonts.googleapis.com/css?family=Pacifico);
    
    
* {
font-family: helvetica, arial, verdana, sans-serif;
}

.feetable {
width: 100%;
border-collapse: collapse;
}

.feetable  th {
background-color: #3774ff;
color: #fff;
text-align: left;
padding: 10px;
border: 1px solid #666;
}

.feetable th{
text-align: left;
border: 1px solid #666;
padding: 10px;
font-size: 11pt;
width: 25%;
}


.feetable td{
    text-align: left;
    border: 1px solid #666;
    padding: 10px;
    font-size: 11pt;
    width: 25%;
    }



.fee_view {
width: 100%;

}

.disclaimer_copy {
font-size: 10pt;
}
