
 @use '../../scss/' as *;
 
 :root {
  
  --listColor:black;
  --bannerColor:white;
  
}

.is_dark {
    --listColor:#f3f3f3;
    --bannerColor:black;
 
}


.policy-header{
    color: var(--listColor);
   font-weight: normal;

}

.policy-bannner{
    background-color: var(--bannerColor);

}