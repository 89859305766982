
:root {
 
  --trade:#f5f9fc;
}

.is_dark {
 
  --trade:#222;
}



.orderbook{
   padding:10px;
  //  background-color: #f5f9fc;
  background-color:var(--trade);
  color:var(--onsurface)
}

th{
    font-size: 13px;
    font-weight: 700;
}




.btn3 {
    background-color: #fff;
   font-size: .8rem;
    border-radius: 3rem;
   box-shadow: none;
   border: 1px solid #523249;
   /* color: #fff; */
   text-transform: uppercase;
   cursor: pointer;
   min-width: 20px;
   transition: all .35s ease-in;
   font-weight: 900;
   color: #000;
   margin: 5px;
   padding-top: 2px;
  padding-right: 10px;
  padding-bottom: 2px;
  padding-left: 10px;
 }
 
 .btn3:hover {
   color:white;
   background-color: #3574ff;
   border-color: white;

 }
 
 



.input-container {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    height:35px;
    
  }
  
  .icon {
    padding: 10px;
    background: #aaa;
    color: white;
    min-width: 5px;
    text-align: center;
    height:8px;
    text-align: center;
    z-index:18px;
    height:35px;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    outline: none;
    border:1px solid #aaa;
    height:35px;
    background-color: transparent;
    color:var(--onsurface);
  }
  
  .input-field:focus {
    border: 2px solid #aaa;
  }
  
  /* Set a style for the submit button */
  .btn12 {
    background-color: #5cb85c;
    font-weight: bold;
    border: none;
    color: white;
    padding: 7px 16px;
    text-align: center;
    text-decoration: none;
    display: block;
    font-size: 15px;
    margin: 2px 1px;
    width:100%;
    cursor: pointer;
    margin-top: 15px;
  }
  
  .btn:hover {
    opacity: 1;
  }
  
  
  .btn1 {
    background-color: #d33535;
    font-weight: bold;
    border: none;
    color: white;
    padding: 7px 16px;
    text-align: center;
    text-decoration: none;
    display: block;
    font-size: 15px;
    margin: 2px 1px;
    width:100%;
    cursor: pointer;
    margin-top: 15px;
  }
  
  .btn1:hover {
    opacity: 1;
  }
  
  
  input.middle:focus {
      outline-width: 0;
  }
  
  
  input,
  .forminput::placeholder {
      font: 11px/3 sans-serif;
      font-weight:560;
  }
  
  
  .forminput {
    text-align: center;
  }
  
  .forminput::-webkit-input-placeholder {
    text-align: center;
  
  }
  
  .forminput:-moz-placeholder {
    text-align: center;
  }
  
  .form-buy{
      margin:15px;
  }