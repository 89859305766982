.wallet-deposit{
  background-color: #2f8956;
  padding: 8px 18px 8px 18px;
  color: white;
}


.wallet-deposit:hover{
  background-color: #5cb85b;
  padding: 8px 18px 8px 18px;
  color: white;
}


.wallet-withdraw{
    background-color: #d33535;
    padding: 8px 18px 8px 18px;
    color: white;
}


.wallet-withdraw:hover{
    background-color: #ff0515;
    padding: 8px 18px 8px 18px;
    color: white;
}

