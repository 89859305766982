.withdraw-tips{
    font-size: 11px;
    color:black;
    padding:1px;
}


.receive-amount{
    font-size: 11px;  
}

.main-amount{
    color:#0380fd;
    font-weight: bold;
    font-size: 20px;
}

.btn-withdraw{
  background-color: #3673ff;
  color:white;
  font-size: 14px;
  float: right;
  padding-right:20px;
  margin-top:15px;
}

.rightside{
    float:right;
    padding-right:10px;
}

.withdraw-header{
    color:#3673ff;
    text-align:center;
    font-size: 25px;
    padding-bottom: 14px;
}

.withdraw-header1
{
    color:#3673ff;
    text-align:center;
    font-size: 25px;
   
}


.withdraw-icon{
    color:#3673ff;
    text-align: center;
    font-size: 40px;
    padding-top: 20px;
    padding-bottom: 10px;
   
}

.withdraw-text{
    color:black;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

.withdraw-main-container{
    margin-top:40px;

    p{
        color:black;
    }

    p:nth-child(2) {
       padding-top:5px; 
      }
}


.withdraw-payment{
    margin-top: 20px;
    color:black;
       p:nth-child(2){
        font-size:13px;
        font-weight: 400;
       }
       p:nth-child(3){
        font-size:13px;
        font-weight: 400;
       }
       p:nth-child(4){
        font-size:13px;
        font-weight: 400;
       }

       p:nth-child(5){
        font-size:13px;
        font-weight: 400;
       }
        p:nth-child(7){
        font-size:18px;
        font-weight: bold;
        color:#3574ff;
        text-align: center;
       }
}





