.download-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
  
    align-items: center;
    text-align: center;
  border-radius: 10px;
   
  }
  
  /* Add a media query for smaller screens */
  @media (max-width: 768px) {
    .download-section {
      grid-template-columns: 1fr;
    }
  
    .app-buttons {
      flex-direction: column;
    }
  }




  .left {
    display: flex;
    flex-direction: column;
 
  }
  
  .download-section h2 {
    font-size: 42px;
    margin-bottom: 10px;
  }
  
  .download-section p {
    font-size: 18px;
    color: #666;
    max-width: 500px;
    width: 100%;
  }
  
  .app-icons {
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .app-icon {
    margin: 0 15px;
  }
  
  .app-store-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .app-store-buttons a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    background-color: #111;
    border-radius: 8px;
    padding: 12px 20px;
    margin: 0 10px;
    transition: background-color 0.3s ease;
  }
  
  .app-store-buttons a span {
    margin-left: 10px;
  }
  
  .app-store-buttons a:hover {
    background-color: black; /* Darker shade on hover */
  }
  
  
  .right {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .app-screenshots {
    max-width: 80%;
   
  }
  
  .app-screenshots img {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  