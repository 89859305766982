:root {
    --main:#f5f9fc;
    --tablemain:#222;
    --tabletd:#ccc;
    --navcolor:#fff;
  }
  
  .is_dark {
    --main:black;
    --tablemain:#ccc;
    --tabletd:#474d57;
    --navcolor:#141416;
  }
  

.launchpad-content{
    border: 1px solid var(--blogcolor);
    padding: 15px;
    background-color: var(--blogcolor);
    border-radius: 7px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

    h6{
        font-size: 18px;
    }

    button{
       
        padding:5px;
        color: white;
        border-radius: 12px;
        border: 3px solid white;
        margin-top: 4px;
        font-weight: bold;
    }

    .right{
        text-align: right;
    }
}

.coin-name{
    color:var(--tablemain);
    padding-left: 7px;
    padding-top: 7px;
}

.coin-symbol{
    height:30px;
    margin-left: 17px;
    margin-top: 5px;
    margin-bottom: 10px;
    color: var(--tablemain);
    background-color: var(--main);
    padding: 3px 10px 3px 10px;
    border-radius: 7px;
    border:1px solid var(--tablemain);

}

.launchpad-timer{
    float: right;
    width: 190px;
    margin-left: 17px;
    margin-top: 5px;
    margin-bottom: 10px;
    color: var(--tablemain);
    background-color: var(--main);
    padding: 10px 20px 10px 20px;
    border-radius: 20px;
    border:1px solid var(--tablemain);  
}


.custom-progress-bar {
    height: 40px; 
  }


  .launchpad-button{
    padding: 5px 12px 5px 12px;
    color: white;
    border-radius: 20px;
    margin-top: 34px;
    font-weight: bold;


 
  }

  .la-success{
      background-color: #16c783;
      border: 3px solid #5cb85c;
  }

  .launchpad-deadline{
      margin-top: 43px;

      
  }


  .launchpad-table{
    margin-top: 40px;
    background-color: var(--navcolor);
    padding: 10px;
    border-radius: 10px;

    div{
        padding:20px;
       
        :first-child {
           font-weight: 600;
           font-size: 13px;
        }

        :nth-child(2){
            color: var(--tablemain);
            font-weight: 600;
        }

        :nth-child(3){
           font-size: 13px;
        }

    }

  }



.la-ta-border{
   border-right: 1px solid #a2a7b2;

}


.launchpad-table > .col-4:nth-child(1),
.launchpad-table > .col-4:nth-child(3),
.launchpad-table > .col-4:nth-child(2) {
    border-bottom: 1px solid #a2a7b2;
}

.launchpad-port{
    background-color:var(--main);
    padding: 20px;
    border-radius: 10px;
    height:120px;

}

.launchpad-port1{
    background-color:var(--main);
    padding: 20px;
    border-radius: 10px;
    height:355px;

}